<template>
  <el-card class="account-link-item">
    <header class="flex-between">
      <div class="w-full">
        <div class="ms-text-l">
          {{ data.receiverAccountName }} ({{ data.receiver.accountNumber | accountNumber }})
        </div>

        <p class="ms-text mt-5">Date: {{ $date(data.createdAt, 'MMMM D YYYY') }}</p>
        <p class="ms-text mt-5">By: {{ data.createdBy }}</p>
      </div>
      <div class="ml-10">
        <el-button
          type="success"
          size="medium"
          :loading="loading"
          round
          @click="unlink()">
          Unlink
        </el-button>
      </div>
    </header>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    async unlink () {
      this.loading = true
      await this.$store.dispatch('linkedAccount/delete', this.data.id)
      this.$notify.success('Successfully unlinked.')
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .account-link-item + .account-link-item {
    margin-top: 15px;
  }
</style>
