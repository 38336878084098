<template>
  <div>
    <ms-select
      v-model="form.account"
      :options="accountOptions"
      class="account-select"
      placeholder="Select Account"
      filterable
      clearable />

    <portal to="actions">
      <el-button
        size="small"
        type="success"
        class="mr-5"
        icon="el-icon-plus"
        :disabled="!form.account"
        circle
        @click="addModal = true" />
    </portal>

    <template v-if="form.account">
      <div
        v-if="loading"
        v-loading="loading"
        class="page-loader" />

      <template v-else>
        <pull-to :top-load-method="reload">
          <account-link-item
            v-for="account in linkedAccounts"
            :key="account.id"
            :data="account"
          />

          <template slot="top-block">
            <div>
              <div
                v-if="reloading"
                v-loading="reloading"
                class="basic-loader mb-20" />
            </div>
          </template>
        </pull-to>
      </template>
    </template>

    <ms-drawer
      v-if="addModal"
      title="New Account Link"
      class="gray"
      @close="addModal = false">

      <ms-input
        v-model="form.accountKey"
        :validator="$v.form.accountKey"
        label="Account Key"
      />

      <template slot="footer">
        <div class="flex-buttons">
          <el-button class="max-w-1-3" round @click="addModal = false">
            Close
          </el-button>
          <el-button
            type="success"
            round
            @click="link()">
            Submit
          </el-button>
        </div>
      </template>
    </ms-drawer>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import PullTo from 'vue-pull-to'
import AccountLinkItem from '@/components/account-link/AccountLinkItem'

export default {
  components: {
    PullTo,
    AccountLinkItem
  },

  data () {
    return {
      form: {
        account: null,
        accountKey: null
      },
      addModal: false,
      loading: false,
      reloading: false,
      saving: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    accountOptions () {
      return this.accounts.map(account => ({
        id: account.id,
        regId: this.registration.id,
        status: account.accountStatus,
        text: `${account.accountNumber}-${this.registration.name}`,
        accountNumber: account.accountNumber,
        provider: account.provider
      })).filter(account => account.status === 'Open')
    },

    linkedAccounts () {
      return this.$store.state.linkedAccount.data || []
    }
  },

  watch: {
    addModal () {
      this.$v.$reset()
      this.form.accountKey = null
      this.saving = false
    },

    'form.account' () {
      this.loadAccountLinks()
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Account Link',
      back: this.previousPageUrl
    })
  },

  methods: {
    async link () {
      this.$v.$touch()
      if (this.$v.form.$invalid) return

      if (this.saving) return

      this.saving = true

      const { action } = await this.$store.dispatch('account/action', {
        id: 'new',
        action: 'CheckAccountKey',
        payload: {
          accountKey: this.form.accountKey,
          sendingAccount: this.form.account
        }
      })

      if (!action || action.error || action?.errorExist || action.errorProvider) {
        if (action?.error) this.$alert('Account Key not found. Please check your information and try again.')
        if (action?.errorExist) this.$alert('Account Link already exists. Please check your information and try again.')
        if (action?.errorProvider) this.$alert('Unable to link to this account.')

        this.saving = false
        return
      }

      try {
        await this.$store.dispatch('linkedAccount/create', {
          createdBy: `${this.user.firstName} ${this.user.lastName}`,
          createdAt: 'CURRENT_TIMESTAMP',
          sendingAccount: this.form.account,
          receivingAccount: action.account.id
        })

        this.$notify.success('Successfully linked.')
        this.addModal = false
        this.saving = false
      } catch (e) {
        console.error(e)
        this.$notify.error(window.errorMessage)
      }
    },

    async loadAccountLinks () {
      this.loading = true
      await this.$store.dispatch('linkedAccount/load', {
        order: ['createdAt:DESC'],
        params: {
          sendingAccount: this.form.account
        }
      })
      this.loading = false
    },

    async reload (loaded) {
      this.reloading = true
      try {
        await this.$store.dispatch('linkedAccount/load', {
          order: ['createdAt:DESC'],
          params: {
            sendingAccount: this.form.account
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        loaded('done')
        this.reloading = false
      }
    }
  },

  validations: {
    form: {
      accountKey: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .account-select {
    margin-bottom: 20px;
  }
</style>
